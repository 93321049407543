import React from 'react';
import {Button, Stack, Typography} from '@mui/material';

export function ErrorState({onRetry}: {onRetry?: () => void}) {
    return (
        <Stack alignItems="center" justifyContent="center">
            <Typography variant="h3">Error</Typography>
            <Typography variant="body2">An error occurred. Please try again.</Typography>
            {onRetry && (
                <Button variant="contained" onClick={onRetry} sx={{mt: 4}}>
                    Retry
                </Button>
            )}
        </Stack>
    );
}
