import {CookieStorage} from 'cookie-storage';
import {makeAutoObservable} from 'mobx';
import {getClientBrowserInfo} from '../helpers/browserInformation';
import LeadsApi from '../api/LeadsApi/LeadsApi';
import LoggingApi from '../api/LoggingApi/LoggingApi';
import updateField from '../helpers/updateField';
import {type RootStore} from './Store';
import {serializable} from './sync/serialization';

class LogStore {
    private parentStore: RootStore;

    @serializable()
    browser = '';

    @serializable()
    os = '';

    @serializable()
    device = '';

    @serializable()
    screen_width = 0;

    @serializable()
    screen_height = 0;

    @serializable()
    orientation = '';

    @serializable()
    viewport = {
        width: 0,
        height: 0,
    };

    constructor(parentStore: RootStore) {
        this.parentStore = parentStore;
        makeAutoObservable(this);
    }

    // NOTE: This is here for backwards compatibility in mixpanel
    @serializable()
    get userId() {
        return this.parentStore.userId;
    }

    async logAction(data: {action: string; data: any}): Promise<void> {
        if (!this.parentStore.userId) {
            throw new Error('A user session does not exist.');
        }

        const params = Object.fromEntries(new URLSearchParams(window.location.search));

        data['$browser'] = this.browser;
        data['$os'] = this.os;
        data['$device'] = this.device;
        data['$screen_height'] = this.screen_height;
        data['$screen_width'] = this.screen_width;
        data['$orientation'] = this.orientation;
        data['$viewport'] = this.viewport;
        data['params'] = params;
        data['eventOrigin'] = this.parentStore.uiStore.progressStore.currentSlideName;

        await LoggingApi.log(this.userId, data, {
            useQueue: this.parentStore.userData.experiment.useQueue,
        });
    }

    async logLead(event: string): Promise<void> {
        if (!this.parentStore.userId) {
            throw new Error('A user session does not exist.');
        }

        const urlParams = new URLSearchParams(window.location.search);
        const cookieStorage = new CookieStorage();
        const mediaSourceCookie = cookieStorage.getItem('mediaSource');
        const utmSource = urlParams.get('utm_source');
        const source = utmSource ? utmSource : mediaSourceCookie;
        const origin = urlParams.get('utm_origin');
        const partner_user_id = urlParams.get('partner_user_id');

        try {
            await LeadsApi.log(
                this.userId,
                source || 'direct_traffic',
                origin,
                event,
                partner_user_id || 'direct_traffic',
            );
        } catch (error) {
            throw new Error(`Failed to log lead event: ${event}`, {cause: error});
        }
    }

    updateField = updateField.bind(this);

    updateBrowserInfo(): void {
        const info = getClientBrowserInfo();

        this.os = info.os;
        this.browser = info.browser;
        this.device = info.device;
        this.screen_height = info.screenHeight;
        this.screen_width = info.screenWidth;
        this.orientation = info.orientation;
        this.viewport = info.viewport;
    }
}

export default LogStore;
