import React, {useEffect, useState} from 'react';

import {CookieStorage} from 'cookie-storage';
import {observer} from 'mobx-react';
import {useRouter} from 'next/router';

import phaseList from '../../store/structures/phaseList';
import {useStore} from '../../components/StoreProvider';

type RouterProps = {
    children?: React.ReactNode;
};

const RouterHoc: React.FC<RouterProps> = (props): JSX.Element => {
    const {} = props;
    const store = useStore();
    const router = useRouter();
    // guarantee utm fields are set before starting to log
    const [hasSetUtm, setHasSetUtm] = useState(false);

    const {progressStore} = store.uiStore;

    const findRoutIndex = (route: string) => phaseList.findIndex((phase) => phase.route === route.replace('/', ''));

    const jumpToPhase = (phase: number) => store.uiStore.progressStore.setProgressBar({percent: 0.0, phase, slide: 0});

    router.beforePopState((e) => {
        const index = findRoutIndex(e.url);

        jumpToPhase(index);

        return false;
    });

    useEffect(() => {
        const {progressStore} = store.uiStore;

        if (progressStore.loading) progressStore.setLoading(false);
    }, [router]);

    useEffect(() => {
        // tracking pageview at hubspot
        setTimeout(() => {
            // need to put this under timeout of 700msec, otherwise the <script defer async/> will not be loaded and inturn it will not have `_hsq` in window
            const _hsq = (window._hsq = window._hsq || []);
            _hsq.push(['setPath', router.asPath]);
            _hsq.push(['trackPageView']);
        }, 700);
    }, [router.asPath]);

    useEffect(() => {
        const {progressStore} = store.uiStore;
        const index = findRoutIndex(progressStore.route);
        const curIndex = findRoutIndex(router.route);

        if (index < curIndex) {
            router.replace(progressStore.route);
        }
        if (index > curIndex) {
            router.push(progressStore.route);
        }
    }, [progressStore.route]);

    const parseParam = (val) => {
        if (val == null) {
            return val;
        }
        if (Array.isArray(val)) {
            if (val.length > 0) {
                return val[0];
            }
            return null;
        }
        return val;
    };

    /**
     * Function to handle utm parameters stored as cookies via GTM
     * Includes most common properties, but can be expanded if necessary
     */
    const getMarketingCookies = () => {
        const cookies = new CookieStorage();
        const marketingCookies = {
            campaign: null,
            medium: null,
            source: null,
            content: null,
            adgroup: null,
        };
        marketingCookies.campaign = cookies.getItem('mediaCampaign');
        marketingCookies.medium = cookies.getItem('mediaMedium');
        marketingCookies.source = cookies.getItem('mediaSource');
        marketingCookies.content = cookies.getItem('mediaContent');
        marketingCookies.adgroup = cookies.getItem('mediaAdgroup');

        return marketingCookies;
    };

    const handleMultiSourceParam = (primaryVal, secondaryVal) => {
        const parsedVal = parseParam(primaryVal);
        if (parsedVal) {
            return parsedVal;
        }

        return secondaryVal ? secondaryVal : '';
    };

    const {query, isReady} = router;

    useEffect(() => {
        try {
            const marketingCookies = getMarketingCookies();
            if (Object.keys(router?.query || {}).length >= 1) {
                store.userData.updateField('utm')({
                    campaign: handleMultiSourceParam(router?.query?.utm_campaign, marketingCookies?.campaign),
                    content: parseParam(router?.query?.utm_content),
                    medium: handleMultiSourceParam(router?.query?.utm_medium, marketingCookies?.medium),
                    source: handleMultiSourceParam(router?.query?.utm_source, marketingCookies?.source),
                    adgroup: handleMultiSourceParam(router?.query?.utm_adgroup, marketingCookies?.adgroup),
                    term: parseParam(router?.query?.utm_term),
                    origin: parseParam(router?.query?.utm_origin),
                    subid: parseParam(router?.query?.utm_subid),
                });
                store.userData.updateField('referral')({
                    rrWcid: parseParam(router?.query?.RR_WCID),
                    rrWcidTtl: parseParam(router?.query?.RR_WCID_TTL),
                    referralCode: parseParam(router?.query?.REFERRALCODE),
                    partnerUserId: parseParam(router?.query?.partner_user_id),
                    callbackUrl: parseParam(router?.query?.callbackUrl),
                });
                if (query?.startRef) store.userData.updateField('startRef')(parseParam(router?.query?.startRef));
                if (store.userData.partners.tuneId === '') {
                    store.userData.updateField('partners')({
                        tuneId: parseParam(router?.query?.tune_id),
                        offerId: parseParam(router?.query?.offer_id),
                        offerName: parseParam(router?.query?.offer_name),
                        affiliateId: parseParam(router?.query?.affiliate_id),
                        source: parseParam(router?.query?.source),
                    });
                }
            } else if (Object.values(marketingCookies).some((cookie) => !!cookie)) {
                store.userData.updateField('utm')({
                    campaign: marketingCookies?.campaign ? marketingCookies?.campaign : '',
                    medium: marketingCookies?.medium ? marketingCookies?.medium : '',
                    source: marketingCookies?.source ? marketingCookies.source : '',
                    content: marketingCookies?.content ? marketingCookies.content : '',
                    adgroup: marketingCookies?.adgroup ? marketingCookies.adgroup : '',
                });
            }
        } catch (e) {
            console.error('Error setting utm', e);
        }

        if (isReady) {
            setHasSetUtm(true);
        }
    }, [query, isReady, store]);

    return hasSetUtm && <>{props.children}</>;
};

export default observer<typeof RouterHoc>(RouterHoc);
