/*
        Reference MixPanel : https://github.com/mixpanel/mixpanel-js/blob/34e5d159c64fb70cd379d8f18e8f3e50dbfb8a49/mixpanel.js#L1252-L1284
*/
export const getClientOS: () => string = () => {
    const a = navigator.userAgent;
    if (/Windows/i.test(a)) {
        if (/Phone/.test(a)) {
            return 'Windows Mobile';
        }
        return 'Windows';
    } else if (/(iPhone|iPad|iPod)/.test(a)) {
        return 'iOS';
    } else if (/Android/.test(a)) {
        return 'Android';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
        return 'BlackBerry';
    } else if (/Mac/i.test(a)) {
        return 'Mac OS X';
    } else if (/Linux/.test(a)) {
        return 'Linux';
    } else {
        return '';
    }
};
export const getClientBrowser: (user_agent: string, opera: any) => string = (user_agent, opera) => {
    if (opera) {
        if (user_agent.includes('Mini')) {
            return 'Opera Mini';
        }
        return 'Opera';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
        return 'BlackBerry';
    } else if (user_agent.includes('FBAN') || user_agent.includes('FBAV') || user_agent.includes('FBIOS')) {
        return 'Facebook Mobile';
    } else if (user_agent.includes('Chrome')) {
        return 'Chrome';
    } else if (user_agent.includes('CriOS')) {
        return 'Chrome iOS';
    } else if (user_agent.includes('Apple')) {
        if (user_agent.includes('Mobile')) {
            return 'Mobile Safari';
        }
        return 'Safari';
    } else if (user_agent.includes('Android')) {
        return 'Android Mobile';
    } else if (user_agent.includes('Konqueror')) {
        return 'Konqueror';
    } else if (user_agent.includes('Firefox')) {
        return 'Firefox';
    } else if (user_agent.includes('MSIE') || user_agent.includes('Trident/')) {
        return 'Internet Explorer';
    } else if (user_agent.includes('Gecko')) {
        return 'Mozilla';
    } else {
        return '';
    }
};
export const getClientDevice: (user_agent: string) => string = (user_agent) => {
    if (/iPad/.test(user_agent)) {
        return 'iPad';
    } else if (/iPod/.test(user_agent)) {
        return 'iPod Touch';
    } else if (/iPhone/.test(user_agent)) {
        return 'iPhone';
    } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
        return 'BlackBerry';
    } else if (/Windows Phone/i.test(user_agent)) {
        return 'Windows Phone';
    } else if (/Android/.test(user_agent)) {
        if (/Mobile/.test(user_agent) && window.innerWidth < 768) {
            return 'Android Phone';
        } else {
            return 'Android Tablet';
        }
    } else if (/Macintosh|Windows|Linux/.test(user_agent)) {
        // Safari on iPad uses a "Macintosh" user agent string by default to provide a desktop-class browsing experience
        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
            return 'Tablet';
        }
        return 'Desktop';
    } else {
        return '';
    }
};

export const getClientOrientation = (): string => {
    return window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait';
};

export const getClientScreenHeight = (): number => {
    return screen.height;
};
export const getClientScreenWidth = (): number => {
    return screen.width;
};

export const getClientViewport = (): {width: number; height: number} => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
};

export function getClientBrowserInfo() {
    const {userAgent} = navigator;

    return {
        os: getClientOS(),
        browser: getClientBrowser(userAgent, window.opera),
        device: getClientDevice(userAgent),
        screenHeight: getClientScreenHeight(),
        screenWidth: getClientScreenWidth(),
        orientation: getClientOrientation(),
        viewport: getClientViewport(),
    };
}

export default {
    getClientBrowser,
    getClientDevice,
    getClientOS,
    getClientOrientation,
    getClientScreenHeight,
    getClientScreenWidth,
    getClientBrowserInfo,
};
