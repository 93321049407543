import {v4 as uuidv4} from 'uuid';

export enum categories {
    initialLanding = 'initial landing',
    intentGoals = 'basic-intent_goals',
    intentGrow = 'basic-intent_grow',
    intentProtect = 'basic-intent_protect',
    intentCashValue = 'basic-intent_grow_cash_value',
    intentInvestmentVehicles = 'basic-intent_grow_investment_vehicles',
    intentTaxAdvantagedAccounts = 'basic-intent_grow_tax_advantaged_accounts',
    intentEmergencyFund = 'basic-intent_grow_emergency_fund',
    intentDependents = 'basic-intent_protect_dependents',
    intentChildren = 'basic-intent_protect_children',
    intentMortgage = 'basic-intent_protect_mortgage',
    intentLivingBenefitCoverage = 'basic-intent_protect_living_benefit_coverage',
    intentRecommendation = 'basic-intent_interstitial_recommendation',
    overallHealth = 'over all health',
    takePlan = 'plan that takes care',
    takeFeature = 'Features in life insurance',
    startIntent = 'Intent to buy life insurance',
    investmentRisk = 'Investment Risk',
    birthDate = 'Date of birth',
    gender = 'Gender',
    zipcode = 'Zipcode',
    address = 'basic-address',
    incomeOccupation = 'Income & occupation',
    estimatedSavingTotal = 'Estimated Savings Total',
    estimatedDebtTotal = 'Estimated Debt Total',
    budget = 'Monthly budget',
    employmentStatus = 'basic-employment-status',
    treatment = 'treatment due to substance abuse or detoxification',
    treatmentDetails = 'treatment-details',
    alcoholDrugFree = 'alcohol or drug-free',
    alcoholicBeverages = 'alcoholic-beverages',
    drinksWeekly = 'health-drinking-frequency',
    health1stSlide = 'Next step',
    healthFamilyMedical = 'health-family-medical',
    smoke = 'Smoker',
    selectIllness = 'Health',
    selectAnyCondition = 'Any condition',
    driving = 'Driving',
    criminalOffense = 'Criminal Offense',
    bankruptcy = 'Bankruptcy',
    preQuotes = 'PreQuotes',
    quotes = 'Quotes',
    preApproval = 'Pre-approval',
    scheduleTime = 'Schedule time',
    family = 'Family life insurance',
    primaryCare = 'health-primary-physician',
    thankYou = 'Thank you',
    aids = 'health-hiv-aids',
    military = 'basic-military-organization',
    existingCoverage = 'basic-annuities',
    travellingOut = 'basic-international-travel',
    extremeSports = 'basic-extreme-activities',
    birthCountry = 'basic-born-location',
    usCitizen = 'basic-citizenship',
    maritalStatus = 'basic-marital-status',
    numberOfChildren = 'Number of Children',
    heightWeight = 'Height Weight',
    landing = 'Landing',
    leadForm = 'LeadForm',
    routeChangeComplete = 'routeChangeComplete',
    any = '',
    name = 'name',
    email = 'email',
}
export enum actions {
    nextBtn = 'Click Next button',
    backBtn = 'Click Back button',
    pageView = 'Page view',
    getApproved = 'Click Get Approved button',
    getPreApproved = 'cp-get-approved-complete',
    submitBtn = 'Click submit button',
    homePageBtn = 'Click home page button',
    land = 'Land on the page',
    quotesWeAreSorry = 'quotes-we-are-sorry',
    routeChange = 'routeChange',
    logoClicked = 'wt-headerLogo-clicked',
    emailIconClicked = 'wt-headerEmailIcon-clicked',
    phoneIconClicked = 'wt-headerPhoneIcon-clicked',
    dobHelperClicked = 'wt-basic-dateOfBirth-helper-clicked',
    householdIncomeTitleInfoClicked = 'wt-basic-annualHouseholdIncome-householdTitleInfo-clicked',
    personalIncomeTitleInfoClicked = 'wt-basic-annualHouseholdIncome-personalTitleInfo-clicked',
    monthlyAmountSliderChanged = 'wt-basic-comfortableAmount-amountSlider-changed',
    recommendedProductTabClicked = 'wt-quotes-recommendation-recommendedProductTab-clicked',
    recommendationTitleInfoClicked = 'wt-quotes-recommendation-recommendationTitleInfo-clicked',
    recommendationInfoTextReadMoreClicked = 'wt-quotes-recommendation-recommendationInfoTextReadMore-clicked',
    recommendationCoverageAmountSliderSlid = 'wt-quotes-recommendation-coverageAmountSlider-slid',
    recommendationCoverageAmountSliderInfoClicked = 'wt-quotes-recommendation-coverageAmountSliderInfo-clicked',
    policyCardEstCashCardInfoClicked = 'wt-quotes-recommendation-policyCardEstCashCardInfo-clicked',
    policyCardMonthlyPremiumInfoClicked = 'wt-quotes-recommendation-policyCardMonthlyPremiumInfo-clicked',
    policyCardFloorLossInfoClicked = 'wt-quotes-recommendation-policyCardFloorLossInfo-clicked',
    policyCardHowButtonClicked = 'wt-quotes-recommendation-policyCardHowButton-clicked',
    pageViewUnder50 = 'page_view_under_50',
}

enum events {
    commonEvent = 'common_event',
}

export enum eventsCustom {
    finishWalkthrough = 'finish_walkthrough',
    submitContactForm = 'submit_contact_form',
    addFullname = 'add_fullname',
    openChat = 'openChat',
}

export const pushEvent = (category: categories, action: actions, payload?: any): void => {
    window?.dataLayer?.push({
        event: events.commonEvent,
        eventId: uuidv4(),
        custom_category: category,
        custom_action: action,
        data_layer_payload: payload,
    });
};

export const pushCustomEvent = (event: eventsCustom | string): void => {
    window?.dataLayer?.push({
        event: event,
    });
};
