import React from 'react';
import * as Sentry from '@sentry/nextjs';
import {PropsWithChildren} from 'react';
import {ErrorBoundary as ReactErrorBoundary, FallbackProps} from 'react-error-boundary';
import {ErrorState} from './ErrorState';
import {Stack} from '@mui/material';

type ErrorBoundaryProps = PropsWithChildren<unknown>;

async function onError(error: Error, info: {componentStack: string}) {
    Sentry.configureScope((scope) => {
        scope.setExtra('componentStack', info.componentStack);
    });
    Sentry.captureException(error);
    await Sentry.flush();
}

function Fallback({resetErrorBoundary}: FallbackProps) {
    return (
        <Stack flexDirection="row" alignItems="center" justifyContent="center" height="100vh" width="100%">
            <ErrorState onRetry={resetErrorBoundary} />
        </Stack>
    );
}

export function GlobalError({children}: ErrorBoundaryProps) {
    return (
        <ReactErrorBoundary FallbackComponent={(props) => <Fallback {...props} />} onError={onError}>
            {children}
        </ReactErrorBoundary>
    );
}

GlobalError.displayName = 'GlobalError';
